import type {
  ErrorBoundaryComponent,
  LinksFunction,
  LoaderArgs,
  LoaderFunction,
  MetaFunction,
} from '@remix-run/node'
import { json } from '@remix-run/node'
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
} from '@remix-run/react'
import tailwindStylesheetUrl from './styles/tailwind.css'
import mainStylesheetUrl from './styles/main.css'
import { getUser } from './session.server'

import { withSentry, setUser } from '@sentry/remix'
import { useEffect } from 'react'

// import c1 from '@uppy/core/dist/style.css'
// import c2 from '@uppy/dashboard/dist/style.css'
// import c3 from '@uppy/image-editor/dist/style.css'

// require('./heap.client')

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: tailwindStylesheetUrl },
    { rel: 'stylesheet', href: mainStylesheetUrl },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto+Mono:wght@300;400;500&family=PT+Mono&display=swap',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'true',
    },
  ]
}

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'Growhire',
  viewport: 'width=device-width,initial-scale=1',
})

export const loader = async ({ request }: LoaderArgs) => {
  return {
    user: await getUser(request),
  }
}

function App() {
  const data = useLoaderData<typeof loader>()

  useEffect(() => {
    if (data.user) {
      setUser({
        id: data.user.id,
        email: data.user.email,
      })
    }
  }, [data.user])

  return (
    <html lang="en" className="h-full">
      <head>
        <Meta />
        {/* <link rel="icon" href="/favicon.png" /> */}
        <Links />
      </head>
      <body className="h-full font-inter">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  )
}

export default withSentry(App)

export const ErrorBoundary: ErrorBoundaryComponent = ({ error }) => {
  return <div className="mx-4 my-12">ERROR: {error.message}</div>
}
